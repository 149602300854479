.middle {
  text-align: center;
  border: 1px solid #d3d3d3;
}

.top-info {
  background-color: red;
  color: #fff;
}

.middle-info {
  background-color: #000;
  color: #fff;
}

.middle-green {
  font-family: Courier New;
  font-size: 18px;
  background-color: #20bc9c;
  color: #fff;
}

.bottom-info {
  font-size: 10px;
  background-color: #384c5c;
  color: #fff;
}

.grow {
  width: 50%;
}

.title {
  font-size: 24px;
}

.table-border {
  border: 1px solid #d3d3d3;
}

.screenshot {
  height: 300px;
  width: 600px;
}

td {
  height: 10px;
}

.wrapper {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.receipt-box {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.receipt-box .actual-receipt {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 7px;
  background-color: #fff;
}

.label-style {
  height: auto;
  display: flex;
  flex-direction: column;
}
